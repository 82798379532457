import request from '@/utils/request';

const PATH = '/apin/groups';

// 최신 그룹
export const getRecentGroupList = () => request({
  url: `${PATH}/recent-groups`,
  method: 'GET',
});

// 최근 활동 그룹 목록
export const getActivityGroupList = () => request({
  url: `${PATH}/activity-groups`,
  method: 'GET',
});

// 인기 그룹
export const getPopularGroupList = () => request({
  url: `${PATH}/like-groups`,
  method: 'GET',
});

// 전체 그룹 검색
export const getSearchGroupList = (params: any) => request({
  url: `${PATH}/search-groups`,
  method: 'GET',
  params,
});

// 참여자 추가
export const addGroupWriters = (groupNo: any, entryList: any) => request({
  url: `${PATH}/group/${groupNo}/add-group-writers`,
  method: 'POST',
  data: {
    groupNo,
    entryList,
  },
})

// 초대자 비밀번호 설정
export const updateNewlyRegisterMembersPassword = (groupNo: any, passwd: any, ids: any) => request({
  url: `${PATH}/group/${groupNo}/update-newly-registerd-writers-password`,
  method: 'POST',
  data: {
    passwd,
    ids,
  },
})

// 그룹 정보 상세 조회
export const getGroupInformation = (roomId: string) => request({
  url: `${PATH}/information`,
  method: 'GET',
  params: {
    room_no: roomId,
  },
})